body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text {
  font-size: 20px;
  font-family: "Source Sans Pro", "Cormorant Garamond", sans-serif, serif;
}
